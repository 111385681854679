import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import { post } from "../../actions/backend"
import { AuthContext } from "../../actions/auth"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginContent = ({store}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const {setMe} = useContext(AuthContext);

  function handleChange(event){
    if(event.target.name === "email"){
      setEmail(event.target.value)
    }else if (event.target.name === "password"){
      setPassword(event.target.value)
    }
  }

  const params = new Proxy(
      new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

  function callAuth(){
    post(
        "auth/",
        {email: email, password: password}
    ).then(response => {
        localStorage.setItem("access", response.data.access)
        localStorage.setItem("refresh", response.data.refresh)
        setMe();
        if(params.after){
          window.location = params.after;
        }else{
          window.location = "/";
        }
    }).catch(error => {
        setFormErrors(error.response.data.error_dict || {})
        toast.error(error.response.data.detail)
    })
  }

    function handleEnter(event) {
     if(event.key === "Enter"){
         callAuth();
     }
  }

  return (
      <div className="edit_profile register login bg_brand">
        <div className="container">
          <div className="row">
            {/*<div className="col-lg-7" />*/}
            <div className="col-12">
              <div className="right_part space-y-20">
                <h1 className="color_white"> Bienvenido a {store? store.name : "Game Stash" } </h1>
                {store? (<p className="color_white">Ingresa con tu cuenta Game Stash</p>) : null }
                <p className="color_white" style={{color: 'white !important'}}>
                  ¿No tienes cuenta?
                  <Link to="/registro" className="color_stroke" > Regístrate </Link>
                </p>
                <div className="box edit_box w-full space-y-20">
                  <div className="space-y-10">
                    <span className="nameInput">Email </span>
                    <div className="confirm">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Ingresa tu email"
                        value={email}
                        onChange={handleChange}
                        onKeyUp={handleEnter}
                      />
                      {formErrors.email ? <small className="color_red">{formErrors.email}</small> : null}
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Contraseña</span>
                    <div className="confirm">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Ingresa tu contraseña"
                        value={password}
                        onChange={handleChange}
                        onKeyUp={handleEnter}
                      />
                      {formErrors.password ? <small className="color_red">{formErrors.password}</small> : null}
                    </div>
                  </div>
                  <button className={"btn bg_brand btn-lg w-full"}
                    onClick={callAuth}>
                    Ingresar
                  </button>
                  <ToastContainer position="bottom-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default LoginContent;
