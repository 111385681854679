import React, {useRef, useState} from 'react';
import Popup from "reactjs-popup";
import { getPaymentOptions, savePaymentDetails } from "../../actions/payments"
import WideButton from "../buttons/WideButton"


function PaymentMethodPopUp({sellerId, cartId, updateCarts, paymentMethod}) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    function savePayment(paymentMethod){
        savePaymentDetails(cartId, paymentMethod, updateCarts);
        closeTooltip();
    }

    return (
        <Popup
          className="custom"
          ref={ref}
          trigger={
              <WideButton text={"Elegir Forma de Pago"} />
          }
          position="bottom center"
          onOpen={() => getPaymentOptions(sellerId, cartId, setPaymentOptions)}
          nested
        >
            <div>
            <div
              className="popup"
              id="popup_bid"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div>
                <button
                  type="button"
                  className="button close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeTooltip}>
                    <span aria-hidden="true">×</span>
                </button>
                  <div className="space-y-20">
                    <h4> Elige tu forma de entrega </h4>
                      {paymentOptions.map((option, i) =>
                        (
                            <WideButton key={i} onClick={()=>{savePayment(option.code)}}
                                      text={option.name} active={paymentMethod === option.code}/>
                      ))}
                  </div>
                </div>
              </div>
            </div>
        </Popup>
    )
}

export default PaymentMethodPopUp;