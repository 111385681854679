import React from 'react';
import style from './FloatingButton.module.css'; 


const FloatingButton = () => {
  return (
    <a 
      href="/carritos" 
      target="_blank" 
      rel="noopener noreferrer"
      className={style.floatingButton}
    >
        <i className="btn btn-white ri-shopping-cart-line"></i>
      
    </a>
  );
};

export default FloatingButton;
