import React from "react";

import { createBrowserRouter} from "react-router-dom";

// Game Stash
import Marketplace from "../views/Marketplace";
import Login from "../views/Login";
import Register from "../views/Register";
import Home from "../views/Home";
import ThankyouPurchase from "../views/ThankyouPurchase";
import CartList from "../views/CartList";



const router = createBrowserRouter([
  {
    path: "/stash/:game_slug",
    element: <Marketplace />,
  },
  {
    path: "/stash/",
    element: <Marketplace />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/registro",
    element: <Register />,
  },
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/gracias",
    element: <ThankyouPurchase/>
  },
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/carritos",
    element: <CartList />
  }
]);



export default router;
