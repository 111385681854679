import React, {useRef, useState} from 'react';
import Popup from "reactjs-popup";
import { getZipCode, createAddress, getAddresses } from "../../actions/delivery"
import WideButton from "../buttons/WideButton"


function AddAddressPopUp({setAddresses}) {
    const [form, setForm] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);

    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    function handleChange(event){
        if(event.target.name === "zip_code"){
            let zip_code = event.target.value.replace(/\D/g, "")
            if (zip_code.length > 5){return null}
            setForm({...form, zip_code: zip_code})
            if (zip_code?.length < 5){return null}
            getZipCode(zip_code, (options) => {
                setNeighborhoodOptions(options);
                if (options.length > 0){
                    setForm({...form, ...options[0], zip_code: zip_code})
                }
            })
        } else {
            setForm({...form, [event.target.name]: event.target.value})
        }
    }

    function saveAddress(){
        createAddress(
            form,
            ()=> {
                getAddresses(setAddresses);
                closeTooltip();
                setForm({});
                setFormErrors({});
                setNeighborhoodOptions([]);
            },
            (error) => setFormErrors(error.response.data.error_dict || {})
        )
    }


    return (
        <Popup
          className="custom"
          ref={ref}
          trigger={
              <WideButton className="text-center" text={"Nueva Dirección"}/>
          }
          position="bottom center"
          nested
          modal
          lockScroll
        >
            <div>
            <div
              className="popup"
              id="popup_bid"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div>
                <button
                  type="button"
                  className="button close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeTooltip}>
                    <span aria-hidden="true">×</span>
                </button>
                  <div className="space-y-20">

                      <div className="form-group col-md-12">
                          <span className="nameInput">Calle</span>
                          <input type="text" className="form-control" name="street"
                                 onChange={handleChange} value={form.street}/>
                          {formErrors.street ? <small className="color_red">{formErrors.street}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Número Exterior</span>
                          <input type="text" className="form-control" name="outdoor_number"
                                 onChange={handleChange} value={form.outdoor_number}/>
                          {formErrors.outdoor_number ?
                              <small className="color_red">{formErrors.outdoor_number}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Número Interior</span>
                          <input type="text" className="form-control" name="interior_number"
                                 onChange={handleChange} value={form.interior_number}/>
                          {formErrors.interior_number ?
                              <small className="color_red">{formErrors.interior_number}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Código Postal</span>
                          <input type="text" className="form-control" name="zip_code"
                                 onChange={handleChange} value={form.zip_code}/>
                          {formErrors.zip_code ? <small className="color_red">{formErrors.zip_code}</small> : null}
                      </div>


                      <div className="form-group col-md-12">
                          <span className="nameInput">Colonia</span>
                          <select className="custom-select form-control-border" name="neighborhood"
                                  onChange={handleChange}>
                              {neighborhoodOptions.map((opt, i) => (
                                      <option key={i}>{opt.neighborhood}</option>
                                  )
                              )}
                          </select>
                          {formErrors.neighborhood ?
                              <small className="color_red">{formErrors.neighborhood}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Ciudad</span>
                          <input type="text" className="form-control" name="city"
                                 value={form.city} disabled/>
                          {formErrors.city ? <small className="color_red">{formErrors.city}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Estado</span>
                          <input type="text" className="form-control" name="state"
                                 value={form.state} disabled/>
                          {formErrors.state ? <small className="color_red">{formErrors.state}</small> : null}
                      </div>

                      <div className="form-group col-md-12">
                          <span className="nameInput">Referencias</span>
                          <input type="text" className="form-control" name="references"
                                 onChange={handleChange} value={form.references} />
                          {formErrors.references ? <small className="color_red">{formErrors.references}</small> : null}
                      </div>

                    <WideButton text={"Guardar Dirección"} active={true}
                                onClick={saveAddress}/>
                  </div>
              </div>
            </div>
            </div>
        </Popup>
    )
}

export default AddAddressPopUp;