import React, {useRef} from 'react';
import Popup from "reactjs-popup";



function PriceReferencePopUp({price_references}) {
    const ref = useRef();
    const closeTooltip = () => ref.current.close();
    return (
        <Popup
          className="custom"
          ref={ref}
          trigger={
            <button className="popup_btn">
              <p
                className="color_text txt_sm view_history"
                style={{width: 'auto'}}>
                  Referencias
              </p>
            </button>
          }
          position="bottom center"
        >
          <div>
            <div
              className="popup"
              id="popup_bid"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div>
                <button
                  type="button"
                  className="button close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeTooltip}>
                    <span aria-hidden="true">×</span>
                </button>
                  <div className="space-y-20">
                    <h4> Referencias de precio </h4>
                      {price_references.map((reference, i) =>
                      (<div className="creator_item creator_card space-x-10" key={i}>
                          <div className="avatars space-x-10">
                            <div>
                              <p className="color_black">
                                  {reference.source}  <span className="color_brand">{reference.version}</span>
                              </p>
                              <span className="date color_text">
                                {reference.display_price}
                              </span>
                            </div>
                          </div>
                    </div>))}
                  </div>
                </div>
              </div>
            </div>
        </Popup>
    )
}

export default PriceReferencePopUp;