import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="footer__1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 space-y-5">
              <div className="footer__logo">
                <Link to="/">
                  <img src={`img/Tlacuache/tlacuache_head_mini.png`} alt="tlacuache" id="tlacuache_head" />
                </Link>
              </div>
              <p className="footer__text">
                ¡Haznos saber tus comentarios, sugerencias
                <br/>
                y/o preguntas a nuestro correo electrónico:
              </p>
              <h3>
                <i className="ri-mail-line"></i>
                &nbsp;&nbsp;contacto@game-stash.mx
              </h3>
              <br/>
              <p className="footer__text">
                Visita nuestras redes sociales.
              </p>
              <div>
                <ul className="footer__social space-x-10 mb-40">
                  <li>
                    <a href="https://www.facebook.com/game.stash.tcg" rel="noreferrer" title="Facebook"  target="_blank">
                      <i className="ri-facebook-line" />
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/5215611672123/" rel="noreferrer"  title="Whatsapp" target="_blank">
                      <i className="ri-whatsapp-line" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/gamestashmx?igsh=cTJ3Z2R4eWtzZHY1" title="Instagram" target="_blank" rel="noreferrer" >
                      <i className="ri-instagram-line"  />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@gamestashmx?_t=8ohoSoKlRTC&_r=1" title="Tiktok" target="_blank" rel="noreferrer" >
                      <i className="ri-music-line" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer_links">
              <h6 className="footer__title">Game Stash</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/stash"> Mercado </Link>
                </li>
                <li>
                  <Link to="/carritos"> Carritos</Link>
                </li>
                <li>
                  <a href="https://link.mercadopago.com.mx/gamestash"
                   title="Donar" target="_blank" rel="noreferrer">
                    Donar </a>
                </li>
                {/*<li>*/}
                {/*  <Link to="/"> Boletín</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/"> FAQ</Link>*/}
                {/*</li>*/}
              </ul>

            </div>
            <div className="col-lg-2 col-6 footer_links">
              {/*<h6 className="footer__title">Tiendas</h6>*/}
              {/*<ul className="footer__list">*/}
              {/*  <li>*/}
              {/*    <Link to="/ravenfolks"> Raven Folks </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/elclub"> El Club </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/2d3"> 2D3 </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/questhobbies"> Quest Hobbies</Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/"> &nbsp;</Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <div className="col-lg-2 col-6 footer_links">
              <h6 className="footer__title">&nbsp;</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/"> &nbsp; </Link>
                </li>
                <li>
                  <Link to="/"> &nbsp; </Link>
                </li>
                <li>
                  <Link to="/"> &nbsp;</Link>
                </li>
                <li>
                  <Link to="/"> &nbsp; </Link>
                </li>
                <li>
                  <Link to="/"> &nbsp; </Link>
                </li>
              </ul>
            </div>
          </div>
          <p className="copyright text-center">
            Copyright © 2024 Game Stash. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
