import React from 'react';
import Header from '../components/Header';
import useDocumentTitle from '../template/components/useDocumentTitle';
import RegisterContent from "../components/main/RegisterContent";
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  useDocumentTitle('Registro');
  return (
    <div>
      <Header />
      <RegisterContent />
    </div>
  );
};

export default Register;
