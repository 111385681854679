import "./template/assets/css/plugins/bootstrap.min.css";
import 'remixicon/fonts/remixicon.css'
import "./template/assets/scss/style.scss";
import {RouterProvider} from "react-router-dom";
import {getMe, AuthContext} from "./actions/auth"
import {getStore, StoreContext} from "./actions/stores"
import { useEffect, useState } from 'react';
import router from "./router/routes";
import storeRouter from "./router/storeRoutes";


function App() {
    const [user, setUser] = useState(null);
    const [store, setStore] = useState(null);


    const setMe = () => {
        getMe(
            setUser,
            () => {setUser(null)}
        )
    }
    useEffect(setMe, [])
    useEffect(() => getStore(window.location.host, setStore), [])

    if (process.env.REACT_APP_GS_URL !== window.location.host) {
        return (
            <div className="App overflow-hidden">
                <StoreContext.Provider value={store}>
                <AuthContext.Provider value={{user: user, setMe: setMe}}>
                    <RouterProvider router={storeRouter} />
                </AuthContext.Provider>
                </StoreContext.Provider>
            </div>
        );
    } else {
        return (
            <div className="App overflow-hidden">
                <AuthContext.Provider value={{user: user, setMe: setMe}}>
                    <RouterProvider router={router} />
                </AuthContext.Provider>
            </div>
        );
    }
}

export default App;
