import {post} from "./backend"
import {createContext} from "react";


export const StoreContext = createContext(null)


export function getStore(domain, setStore){
    post(
        "stores/by_domain/",
        {domain: domain},
        false,
    ).then(response => {
          setStore(response.data)
        }
    ).catch((error) => {
        if(error.status === 404){setStore(null)}
    })
}
