import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';
import {getGames} from "../../actions/games";




export default function GamesDisplay() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    margin: 20,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };




  // Create State for list of games that can be used in filters
  const [games, setGames] = useState([]);

   // Effect to get games from backend, not dependencies as this should be only called once
  useEffect(() => getGames(setGames), []);


  return (
    <div className="section__artists mt-100">
      <div className="container">
        <div className="space-y-30">
          <div className="section_head">
            <h2 className="section__title mt-10">
              Juegos&nbsp;
              <i className="ri-bubble-chart-line"></i>
            </h2>
          </div>

          <div className="section_body swiper_artists">
            <Slider {...settings}>
              {games.map((game, i) => (
                <div className="item" key={i}>
                  <div className="creator_item creator_card rounded_border space-x-10">
                    <div>


                        <Link to={`/stash/${game.slug}`} key={i}>
                          <img
                            src={game.logo}

                            alt={game.name}
                            className={"img-fluid"}
                          />
                        </Link>


                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
