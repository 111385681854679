import React from "react";
import style from "../popups/SellersPopUp.module.scss";
import {post} from "../../actions/backend";
import {toast} from "react-toastify";
import cartUnauthorizedToast from "../toasts/cartUnauthorizedToast";


function CartItemButtons ({quantity, availabilityUuid, updateQuantity}){
    function addCart(availabilityUuid){
        post(
            "cart/add/",
            {availability: availabilityUuid},
            true
        ).then(
            response => {
                updateQuantity(availabilityUuid, response.data.in_cart)
            }
        ).catch(error => {
            if (error.response.status === 403){
                toast.error(cartUnauthorizedToast)
            }else{
                toast.error(error.response.data.detail)
            }
        })
    }

    function removeCart(availabilityUuid){
        post(
            "cart/remove/",
            {availability: availabilityUuid},
            true,
        ).then(
            response => {
                updateQuantity(availabilityUuid, response.data.in_cart)
            }
        ).catch(error => {
            if (error.response.status === 403){
                toast.error(cartUnauthorizedToast)
            }else{
                toast.error(error.response.data.detail)
            }
        })
    }



    return (
        <span>
            <button onClick={() => removeCart(availabilityUuid)}
                    className={`btn ${style.cart_button} ${style.left_button}`}>
                -
            </button>
            <button className={`btn ${style.quantity}`} disabled>
                { quantity }
            </button>
            <button onClick={() => addCart(availabilityUuid)}
                    className={`btn ${style.cart_button} ${style.right_button}`}>
                +
            </button>
        </span>
    )
}

export default CartItemButtons;