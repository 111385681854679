import {get} from "./backend"


export function getProducts(setProducts, setPageCount, game_slug, search, productType, page, sellerId){
    let params = {
        page:page,
        game: game_slug,
        search: search,
        type_of: productType,
        order_by: "market",
        seller: sellerId,
    }
    if (sellerId){
        params.is_available_on_market = true;
    }
    get(
        "products/",
        params,
    ).then(response => {
          setProducts(response.data.results);
          setPageCount(response.data.page_count);
        }
    )
}


export function getCartByAvailability(setCartAvailabilities){
    get(
        "cart/quantity_by_availability",
        {},
        true,
    ).then(response => {
            setCartAvailabilities(response.data);
        }
    ).catch((error) => {setCartAvailabilities({})})
}
