import {get, post} from "./backend"
// import {toast} from "react-toastify";
// import cartUnauthorizedToast from "../components/toasts/cartUnauthorizedToast";


export function getCarts(status, storeId, handleResponse, handleError){
    get(
        "cart/",
        {status: status, store: storeId},
        true,
    ).then(response => {
          handleResponse(response.data);
        }
    ).catch((error) => {
        handleError(error)
    })
}

export function submitCart(cartId, handleResponse){
    post(
        `cart/${cartId}/checkout/`,
        {},
        true,
    ).then(response => {
          handleResponse(response.data);
        }
    )
}
