import React, {useRef, useState} from 'react';
import Popup from "reactjs-popup";
import { getAddresses, getShippingOptions } from "../../actions/delivery"
import WideButton from "../buttons/WideButton"
import AddAddressPopUp from "./AddAddressPopUp"


function ShippingPopUp({sellerId, cartId, saveDelivery, active}) {
    const [addresses, setAddresses] = useState([]);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [address, setAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    function selectAddress(addressId){
        setLoading(true)
        setAddress(addressId)
        getShippingOptions(sellerId, cartId, addressId, (data) => {
            setLoading(false)
            setShippingOptions(data)
        })
    }

    function selectShipping(option){
        saveDelivery({
            delivery_type: "shipping",
            shipping_address: address,
            shipping: {
                name: option.name,
                service: option.service,
                time: option.time,
                price: option.price,
                extra_data: option.extra_data,
            }
        })
    }


    return (
        <Popup
          className="custom"
          ref={ref}
          trigger={
              <WideButton className="text-center" text={"Envio"} active={active}/>
          }
          position="bottom center"
          onOpen={() => getAddresses(setAddresses)}
          nested
          modal
          lockScroll
        >
            <div>
            <div
              className="popup"
              id="popup_bid"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div>
                <button
                  type="button"
                  className="button close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeTooltip}>
                    <span aria-hidden="true">×</span>
                </button>
                  <div className="space-y-20">
                    <h4> Elige tu direccion </h4>
                      {addresses.map((option, i) => (
                          <WideButton key={i} onClick={() => selectAddress(option.id)}
                            active={option.id === address}
                            text={`${option.street} ${option.outdoor_number} ${option.zip_code} ${option.neighborhood}`}
                          />
                      ))}
                      <AddAddressPopUp setAddresses={setAddresses}/>
                    <h4>Elige tu forma de envio</h4>
                      {loading ? (
                              <div>
                                  <p className="text-center"> Estamos cotizando tu envio</p>
                                  <div className="d-flex justify-content-center">
                                      <div className="spinner-border" role="status">
                                      </div>
                                  </div>
                              </div>
                          ) :
                          shippingOptions.map((option, i) => (
                              <WideButton onClick={() => selectShipping(option)}
                                text={(
                                    <div>
                                    <p>{option.name} {option.time}</p>
                                    <p>{option.display_price}</p>
                                    </div>
                                )}
                                />
                      ))

                      }
                  </div>
                </div>
              </div>
            </div>
        </Popup>
    )
}

export default ShippingPopUp;