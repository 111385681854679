import React, {useContext} from 'react';

import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import MarketProducts from '../../components/main/MarketProducts';
import {ToastContainer} from 'react-toastify';
import {StoreContext} from "../../actions/stores"
import useDocumentTitle from "../../template/components/useDocumentTitle";


const Marketplace = () => {
  const store = useContext(StoreContext)
  useDocumentTitle(store?.name);
  if(store === null){return null}

  return (
    <div>
      <Header searchRedirect={false}/>
      <Hero />
      <div className="d-flex justify-content-center">
        <MarketProducts store={store}/>
      </div>
      <ToastContainer position={"bottom-right"}/>
      <Footer />
    </div>
  );
};

export default Marketplace;
