import React from "react";
import CartItemButtons from "../buttons/CartItemButtons"
import style from "./CartItemRow.module.scss"

const CartItemRow = ({item, isActive, updateCarts}) => {

    return (
        <div className={`row ${style.item}`}>
            <div className="col-2">
                <img className={style.image} src={item.availability.card.image}/>
            </div>
            <div className="col-10">
                <h3>
                    {item.availability.card.name}
                    <small> ({item.availability.card.expansion} {item.availability.card.numeration})</small>
                </h3>
                <div className="d-flex justify-content-between color_brand my-3">
                    <span><b>Versión:</b> {item.availability.version} {item.availability.language}</span>
                    <span className="color_green">{item.price} c/u x {item.quantity}</span>
                    <span className="color_green">{item.total_price}</span>
                </div>
                {
                    isActive ? (
                        <CartItemButtons
                            quantity={item.quantity}
                            availabilityUuid={item.availability.uuid}
                            updateQuantity={updateCarts}/>
                    ) : <span><b>Cantidad:</b> {item.quantity}</span>
                }
            </div>
        </div>
    )
}

export default CartItemRow;