import React, {useContext} from 'react';
import Header from './Header';
import useDocumentTitle from '../../template/components/useDocumentTitle';
import LoginContent from "../../components/main/LoginContent"
import 'react-toastify/dist/ReactToastify.css';
import {StoreContext} from "../../actions/stores";

const Login = () => {
  useDocumentTitle('Login ');
  const store = useContext(StoreContext)
  if(store === null){return null}

  return (
    <div>
      <Header />
      <LoginContent store={store}/>
    </div>
  );
};

export default Login;
