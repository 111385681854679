import {get, post} from "./backend";


export function getDeliveryOptions(sellerId, setDeliveryOptions){
    get(
        `delivery/${sellerId}/options/`
    ).then(response => {
        setDeliveryOptions(response.data)
    })
}

export function saveDeliveryDetails(cartUuid, data, handleResponse){
    post(
        `cart/${cartUuid}/delivery/`,
        data,
        true,
    ).then(response => {
        handleResponse(response.data)
    })
}

export function getAddresses(setAddresses){
    get(
        "addresses",
        {},
        true,
    ).then(response => {
        setAddresses(response.data)
    })
}

export function getShippingOptions(sellerUuid, cartUuid, addressId, setOptions){
    post(
        `delivery/${sellerUuid}/shipping/`,
        {
            address: addressId,
            cart: cartUuid,
        },
        true,
    ).then(response => {
        setOptions(response.data)
    })
}

export function getZipCode(zip_code, handleResponse){
    get(
        "delivery/zip_code",
        {zip_code: zip_code}
    ).then(response => {
        handleResponse(response.data);
    })
}

export function createAddress(data, handleResponse, handleError){
    post(
        "addresses/",
        data,
        true,
    ).then(response => {
        handleResponse(response.data)
    }).catch(error => handleError(error))
}