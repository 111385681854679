import {Link} from "react-router-dom";
import React from "react";


const cartUnauthorizedToast = () => (
      <div>
        Para agregar articulos a tu carrito primero
        <Link to="/login"> ingresa a tu cuenta.</Link>
      </div>
    );

export default cartUnauthorizedToast;
