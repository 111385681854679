import React from 'react';

import 'reactjs-popup/dist/index.css';
const HeroCart = () => {

  return (
    <div className="hero_Live_Auctions">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 space-y-10 left_content">
            <i className="ri-shopping-cart-line ri-3x"></i>
            <h1 className="text-left">Mis Carritos</h1>
            <p>
              Aquí podrás ver los carritos de compras que tienes actualmente,
              se creará un carrito por cada vendedor del que tengas productos,
              recuerda que se hará una compra independiente con cada vendedor.
            </p>
          </div>
          <div className="col-lg-5">
              <img
                className="img-fluid hero__mini"
                id="tlacuache_right"
                src="/img/Tlacuache/tlacu_comprando_png_left.png"
                alt="tlacuache"
              />
            </div>

        </div>
      </div>
    </div>
  );
};

export default HeroCart;
