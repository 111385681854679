import React from "react";

import { createBrowserRouter} from "react-router-dom";

// Game Stash
import Marketplace from "../views/store/Marketplace";
import Login from "../views/store/Login";
import Register from "../views/store/Register";
import ThankyouPurchase from "../views/ThankyouPurchase";
import Cart from "../views/store/Cart";



const storeRouter = createBrowserRouter([
  {
    path: "/",
    element: <Marketplace />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/registro",
    element: <Register />,
  },
  {
    path: "/gracias",
    element: <ThankyouPurchase/>
  },
  {
    path: "/carritos",
    element: <Cart />
  },
  {
    path: "/:game_slug",
    element: <Marketplace />,
  },
]);



export default storeRouter;
