import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { post } from "../../actions/backend"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterContent = () => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  function handleChange(event){
    setForm({...form, [event.target.name]: event.target.value})
  }

  function createAccount(){
    if (form.password !== form.password2){
      setFormErrors({password2: "Tus contraseñas no coinciden."})
      return null
    }
    post(
        "users/",
        form
    ).then(response => {
      callAuth();
    }).catch(error => {
        setFormErrors(error.response.data.error_dict || {})
        toast.error(error.response.data.detail)
    })
  }

  function callAuth(){
    post(
        "auth/",
        {email: form.email, password: form.password}
    ).then(response => {
        localStorage.setItem("access", response.data.access)
        localStorage.setItem("refresh", response.data.refresh)
        window.location = "/";
    }).catch(error => {
        setFormErrors(error.response.data.error_dict || {})
        toast.error(error.response.data.detail)
    })
  }

  return (
      <div className="edit_profile register login bg_brand">
        <div className="container">
          <div className="row">
            {/*<div className="col-lg-7" />*/}
            <div className="col-12">
              <div className="right_part space-y-20">
                <h1 className="color_white"> Crea tu cuenta Game Stash </h1>
                <p className="color_white" style={{color: 'white !important'}}>
                  ¿Ya tienes cuenta?
                  <Link to="/login" className="color_stroke"> Ingresa </Link>
                </p>
                <div className="box edit_box w-full space-y-20">
                  <div className="space-y-10">
                    <span className="nameInput">Nombre </span>
                    <div className="confirm">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Ingresa tu nombre completo"
                        value={form.name}
                        onChange={handleChange}
                      />
                      {formErrors.name ? <small className="color_red">{formErrors.name}</small> : null}
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Teléfono </span>
                    <div className="confirm">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Ingresa tu teléfono a 10 dígitos"
                        value={form.phone}
                        onChange={handleChange}
                      />
                      {formErrors.email ? <small className="color_red">{formErrors.email}</small> : null}
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Email </span>
                    <div className="confirm">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Ingresa tu email"
                        value={form.email}
                        onChange={handleChange}
                      />
                      {formErrors.email ? <small className="color_red">{formErrors.email}</small> : null}
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Contraseña</span>
                    <div className="confirm">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Ingresa tu contraseña"
                        value={form.password}
                        onChange={handleChange}
                      />
                      {formErrors.password ? <small className="color_red">{formErrors.password}</small> : null}
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Confirma tu Contraseña</span>
                    <div className="confirm">
                      <input
                        type="password"
                        name="password2"
                        className="form-control"
                        placeholder="Ingresa tu contraseña nuevamente"
                        value={form.password2}
                        onChange={handleChange}
                      />
                      {formErrors.password2 ? <small className="color_red">{formErrors.password2}</small> : null}
                    </div>
                  </div>
                  <button className={"btn bg_brand btn-lg w-full"}
                    onClick={createAccount}>
                    Registrarme
                  </button>
                  <ToastContainer position="bottom-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default RegisterContent;
