import React from 'react';

import Footer from '../template/components/footer/Footer';
import Header from '../components/Header';
import HeroMarket from '../components/hero/HeroMarket';
import useDocumentTitle from '../template/components/useDocumentTitle';
import MarketProducts from '../components/main/MarketProducts';
import {ToastContainer} from 'react-toastify';
import FloatingButton from '../components/buttons/FloatingButton';

const Marketplace = () => {
  useDocumentTitle('Stash');
  return (
    <div>
      <Header searchRedirect={false} />
      <HeroMarket />
      <div className="d-flex justify-content-center">
        <MarketProducts />
      </div>
      <ToastContainer position={"bottom-right"}/>
      <FloatingButton/>
      <Footer />
    </div>
  );
};

export default Marketplace;
