import {get} from "./backend"
import {createContext} from "react";


export const AuthContext = createContext(null)

export function getMe(handleResponse, handleError){
    get(
        "users/me/",
        {},
        true,
    ).then(response => {
          handleResponse(response.data);
        }
    ).catch((error) => {
        handleError(error)
    })
}