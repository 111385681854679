import React from 'react';

import Footer from '../template/components/footer/Footer';
import Header from '../components/Header';
import useDocumentTitle from '../template/components/useDocumentTitle';
import HeroCart from "../components/hero/HeroCart";
import {ToastContainer} from 'react-toastify';
import CartListContent from "../components/main/CartListContent"


const CartList = () => {
  useDocumentTitle(' Carritos');
  return (
      <div>
          <Header/>
          <HeroCart />

          <CartListContent />

          <Footer/>
          <ToastContainer position={"bottom-right"}/>
      </div>
  );
};

export default CartList;
