import Popup from "reactjs-popup";
import React, {useContext, useRef, useState} from "react";
import {get} from "../../actions/backend";
import CartItemButtons from "../buttons/CartItemButtons"
import {StoreContext} from "../../actions/stores";
import WideButton2 from "../buttons/WideButton2";

function SellersPopUp({product_id, cartAvailabilities, setCartAvailabilities}){
    const ref = useRef();
    const [availability, setAvailability] = useState({availability: []});
    const store = useContext(StoreContext)


    function getAvailability() {
        get(
            `products/${product_id}/availability`,
            {seller: store?.user}
        ).then(response => {
            setAvailability(response.data)
        })
    }

    function updateQuantity(availabilityUuid, quantity){
        setCartAvailabilities({...cartAvailabilities, [availabilityUuid]: quantity})
    }

    const closeTooltip = () => ref.current.close();
    return (
        <Popup
            className="custom"
            ref={ref}
            trigger={
                <button className="btn btn-sm bg_green">
                    {store ? "Ver Versiones" : "Ver Vendedores"}
                </button>
            }
            position="bottom center"
            onOpen={getAvailability}
            modal
            lockScroll
        >
            <h4> {availability.name} </h4>
            <h5> {availability.expansion} {availability.numeration} </h5>
            <div>
                <div
                    className="popup"
                    id="popup_bid"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true">
                    <div>
                        <button
                            type="button"
                            className="button close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeTooltip}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className=" space-y-20">
                            {
                                availability.availability.map((data, i) => (
                                    <div className="creator_item creator_card space-x-10" key={i}>
                                        <div className="avatars space-x-10">
                                            <div className="media">
                                                { data.seller.store ? (
                                                    // <Link to="profile">
                                                        <img
                                                            src={data.seller.store.logo}
                                                            alt="Logo"
                                                            className="img-fluid"
                                                            style={{maxWidth: "90px"}}
                                                        />
                                                    // </Link>
                                                ) : null}
                                            </div>
                                            <div>
                                                <p className="color_black">
                                                    <b>Vendedor:</b> {data.seller.store ? data.seller.store.name : data.seller.name}
                                                </p>
                                                <p className="color_black">
                                                    <small>
                                                        {data.seller.address?.city}, {data.seller.address?.state}
                                                    </small>
                                                </p>
                                                <p className="color_text">
                                                    <b>Versión:</b> {data.version} {data.language}
                                                </p>
                                                <p className="color_text">
                                                    <b>Precio:</b> {data.price}
                                                </p>
                                                <div>
                                                    <span className={"mr-5"}>
                                                        {data.quantity >= 5 ? "5+" : data.quantity } disponibles
                                                    </span>
                                                    <CartItemButtons
                                                        quantity={cartAvailabilities[data.uuid] || 0}
                                                        availabilityUuid={data.uuid}
                                                        updateQuantity={updateQuantity}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }                                       
                        </div>
                    </div>
                </div>
            </div>
            <WideButton2 
                text={"Ir a mi carrito"}
                active={true}
                onClick={() => {window.location.href = "/carritos"}}>
            </WideButton2>
        </Popup>
    )
}


export default SellersPopUp;