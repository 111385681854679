import React from 'react';


function Call2action() {
  return (
    <div>
      <div className="call2action">
        <div className="container">
          <div
            className="row justify-content-between align-items-center
                        sm:space-y-10">
            <div className="col-md-7">
              <div className="space-y-20">
                <h1 className="text-white">
                  {/*Crea tu carpeta digital*/}
                  ¿Quieres vender a través de Game Stash?
                </h1>
                <p className="color_light section__text">
                  Tus cartas o juegos podrán ser vistos por muchas más personas.
                  Puedes vender, recibir pagos y generar guías de envío a través de la plataforma.
                  <br/>
                  <br/>
                  Escríbenos a contacto@game-stash.mx
                </p>
                {/*<Link*/}
                {/*  to="/ventas"*/}
                {/*  className="btn*/}
                {/*                    btn-primary">*/}
                {/*  Comenzar*/}
                {/*</Link>*/}
              </div>
            </div>
            <div className="col-lg-5">
              <img
                className="img-fluid hero__img"
                alt="hamster_negrito"
                src={`img/Tlacuache/hamster_negrito_mini.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Call2action;
