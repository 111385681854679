import React from 'react';
import Header from '../components/Header';
import useDocumentTitle from '../template/components/useDocumentTitle';
import LoginContent from "../components/main/LoginContent"
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  useDocumentTitle('Login ');

  return (
    <div>
      <Header />
      <LoginContent />
    </div>
  );
};

export default Login;
