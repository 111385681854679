import React from "react";
import style from "./WideButton.module.scss";


function WideButton ({text, active, onClick}){
    return (
        <button onClick={onClick}
                className={`${style.button} ${active ? style.active : null}`}>
            {text}
        </button>
    )
}

export default WideButton;