import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="footer__1">
        <div className="container">
          <p className="copyright text-center">
              Powered by <Link to={"https://game-stash.mx"}><span>Game Stash</span></Link>.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
