import React from 'react';

import Footer from './Footer';
import Header from './Header';
import {ToastContainer} from 'react-toastify';
import CartListContent from "../../components/main/CartListContent"


const Cart = () => {
  return (
      <div>
          <Header/>

          <CartListContent />

          <Footer/>
          <ToastContainer position={"bottom-right"}/>
      </div>
  );
};

export default Cart;
