import React, {useContext} from 'react';
import Header from './Header';
import useDocumentTitle from '../../template/components/useDocumentTitle';
import RegisterContent from "../../components/main/RegisterContent";
import 'react-toastify/dist/ReactToastify.css';
import {StoreContext} from "../../actions/stores";

const Register = () => {
  useDocumentTitle('Registro');
  const store = useContext(StoreContext)
  if(store === null){return null}

  return (
    <div>
      <Header />
      <RegisterContent />
    </div>
  );
};

export default Register;
