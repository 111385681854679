import React, {useRef, useState} from 'react';
import Popup from "reactjs-popup";
import { getDeliveryOptions, saveDeliveryDetails } from "../../actions/delivery"
import ShippingPopUp from "./ShippingPopUp"
import WideButton from "../buttons/WideButton"

function DeliveryTypePopUp({sellerId, cartId, updateCarts, deliveryType}) {
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const ref = useRef();
    const closeTooltip = () => ref.current.close();


    function saveDelivery(data){
        saveDeliveryDetails(cartId, data, () => {
            updateCarts();
            closeTooltip();
        })
    }


    return (
        <Popup
          className="custom"
          ref={ref}
          trigger={
              <WideButton text={"Elegir Forma de Entrega"}/>
          }
          position="bottom center"
          onOpen={() => getDeliveryOptions(sellerId, setDeliveryOptions)}
          nested
          modal
          lockScroll
        >
            <div>
            <div
              className="popup"
              id="popup_bid"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div>
                <button
                  type="button"
                  className="button close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeTooltip}>
                    <span aria-hidden="true">×</span>
                </button>
                  <div className="space-y-20">
                    <h4> Elige tu forma de entrega </h4>
                      {deliveryOptions.map((option, i) =>
                          option.code === "shipping" ?
                        (
                        <ShippingPopUp key={i} sellerId={sellerId} cartId={cartId}
                           active={option.code === deliveryType} saveDelivery={saveDelivery}/>
                        ):
                        ( <WideButton key={i} text={option.name} active={option.code === deliveryType}
                              onClick={()=>{saveDelivery({delivery_type: option.code})}} />

                      ))}
                  </div>
                </div>
              </div>
            </div>
        </Popup>
    )
}

export default DeliveryTypePopUp;