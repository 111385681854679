import React, {useState, useEffect, useContext} from 'react';
import {useSearchParams} from "react-router-dom";
import {StoreContext} from "../../actions/stores";


function Search({classes, styles, buttonClasses, redirect = true}){
  const store = useContext(StoreContext)
  let [queryParams, setQueryParams] = useSearchParams();
  const [search, setSearch] = useState(queryParams.get("search") || "")

  // Sets the search property on queryParams, changing the url to include a query param
  // ?search=<current value of search state>
  function setSearchQueryParam(){
    let path = store ? "/" : "/stash"
    if (redirect){
        window.location = `${path}?search=${search}`
    } else {
      const element = document.getElementById('productList');
      element?.scrollIntoView({behavior: 'smooth'});
    }
    setQueryParams({...queryParams, search: search})
  }

  // Receives an event and gets the relevant property on it to update search state value
  function changeSearch(event){
    setSearch(event.target.value);
  }

  // Listens every time a key is pressed on search input and only if that key was enter,
  // calls set Search query param
  function handleEnter(event) {
     if(event.key === "Enter"){
         setSearchQueryParam();
     }
  }

  // Guarantee search field is changed if url is changed, main purpose is to delete the search input
  // on url change
  useEffect(() => {
    setSearch(queryParams.get("search") || "")
  }, [queryParams]);

  return (
      <div className={classes} style={styles}>
          <input type="text" name="search" placeholder="¿Que estás buscando?"
                 value={search} onChange={changeSearch} onKeyUp={handleEnter}/>
          <button className={buttonClasses} onClick={setSearchQueryParam}>
              <i className="ri-search-line"/>
          </button>
      </div>
  )
}

export default Search;