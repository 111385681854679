import React, {useContext, useEffect, useState} from 'react';

import CartCard from "../cards/CartCard"
import {getCarts} from "../../actions/cart";
import style from "./MarketProducts.module.scss";
import {toast} from "react-toastify";
import {StoreContext} from "../../actions/stores";

const CartListContent = () => {
  const [carts, setCarts] = useState([]);
  const [cartStatus, setCartStatus] = useState("active");
  const store = useContext(StoreContext)

  const updateCarts = () => getCarts(
      cartStatus,
      store?.uuid,
      setCarts,
      (error) => {
          if(error.response.status === 403){
              window.location = "/login?after=/carritos"
          }else{
              toast.error("Ocurrió un error")
          }
    }
  )

  useEffect(updateCarts, [cartStatus, store]);




  return (
      <div className="mt-100">
          <div className="container">
              <div className="row justify-content-between align-items-center section__head">
                  <div className="col-lg-auto">
                      <div className="menu_categories menu_start">
                          <div className="switch_item">
                              <span className={`btn btn-sm ${cartStatus === "active" ? style.active : "btn-white"}`}
                                    onClick={() => {
                                        setCartStatus("active")
                                    }}>
                                Carritos Activos
                              </span>
                          </div>
                          <div className="switch_item">
                              <span className={`btn btn-sm ${cartStatus === "on_hold" ? style.active : "btn-white"}`}
                                    onClick={() => {
                                        setCartStatus("on_hold")
                                    }}>
                                Procesando por Vendedor
                              </span>
                          </div>
                      </div>
                  </div>
              </div>


              <div className="row">
                  {carts.map((cart, i) =>
                      <CartCard key={i} cart={cart} updateCarts={updateCarts}/>)}
              </div>
          </div>
      </div>
  );
};

export default CartListContent;
